// Mat Body
.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
    letter-spacing: normal;
    color: var(--blackColor);
    font: {
        weight: 400;
        size: var(--fontSize);
        family: var(--fontFamily);
    };
}

// Mat Paragraph
.mat-body p, .mat-body-2 p, .mat-typography .mat-body p, .mat-typography .mat-body-2 p, .mat-typography p {
    font-size: var(--fontSize);
    color: var(--bodyColor);
    line-height: 1.8;
    margin: {
        bottom: 15px;
        top: 0;
    };
    &:last-child {
        margin-bottom: 0;
    }
}

// Mat Heading
.mat-h1, .mat-headline-5, .mat-typography .mat-h1, .mat-typography .mat-headline-5, .mat-typography h1, .h1, .display-1 {
    color: var(--blackColor);
    font: {
        size: 38px;
        weight: 500;
        family: var(--fontFamily);
    };
    margin: {
        top: 0;
        bottom: 15px;
    };
}
.mat-h2, .mat-headline-6, .mat-typography .mat-h2, .mat-typography .mat-headline-6, .mat-typography h2, .h2, .display-2 {
    color: var(--blackColor);
    font: {
        size: 30px;
        weight: 500;
        family: var(--fontFamily);
    };
    margin: {
        top: 0;
        bottom: 15px;
    };
}
.mat-h3, .mat-subtitle-1, .mat-typography .mat-h3, .mat-typography .mat-subtitle-1, .mat-typography h3, .h3, .display-3 {
    color: var(--blackColor);
    font: {
        size: 26px;
        weight: 500;
        family: var(--fontFamily);
    };
    margin: {
        top: 0;
        bottom: 15px;
    };
}
.mat-h4, .mat-body-1, .mat-typography .mat-h4, .mat-typography .mat-body-1, .mat-typography h4, .h4, .display-4 {
    color: var(--blackColor);
    font: {
        size: 22px;
        weight: 500;
        family: var(--fontFamily);
    };
    margin: {
        top: 0;
        bottom: 15px;
    };
}
.mat-h5, .mat-typography .mat-h5, .mat-typography h5, .h5, .display-5 {
    color: var(--blackColor);
    font: {
        size: 18px;
        weight: 500;
        family: var(--fontFamily);
    };
    margin: {
        top: 0;
        bottom: 15px;
    };
}
.mat-h6, .mat-typography .mat-h6, .mat-typography h6, .h6, .display-6 {
    color: var(--blackColor);
    font: {
        size: 14px;
        weight: 500;
        family: var(--fontFamily);
    };
    margin: {
        top: 0;
        bottom: 15px;
    };
}

// Blockquotes
.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;

    &>:last-child {
        margin-bottom: 0;
    }
}
blockquote {
    margin: 0 0 1rem;
}
.blockquote-footer {
    font-size: .875em;
    color: #6c757d;
    margin: {
        top: -.5rem;
        bottom: 1rem;
    };
    &::before {
        content: "— ";
        margin-right: 3px;
    }
}

// Mat Card
.mat-mdc-card {
    &.daxa-card {
        padding: 20px;
        box-shadow: unset;

        .mat-mdc-card-header {
            margin-bottom: 20px;
            display: block;
            padding: 0;

            .mat-mdc-card-header-text {
                justify-content: space-between;
                align-items: center;
                display: flex;

                .mat-mdc-card-title {
                    .search-box {
                        width: 350px;

                        i {
                            transform: translateY(-50%);
                            color: var(--blackColor);
                            position: absolute;
                            font-size: 18px;
                            left: 13px;
                            top: 50%;
                        }
                        .input-search {
                            height: 40px;
                            font-size: 14px;
                            border-radius: 3px;
                            background: #f4f6fc;
                            color: var(--blackColor);
                            padding: {
                                left: 40px;
                                right: 15px;
                            };
                            &::placeholder {
                                font-weight: 300;
                                color: var(--bodyColor);
                                transition: var(--transition);
                            }
                            &:focus {
                                &::placeholder {
                                    color: transparent;
                                }
                            }
                        }
                    }
                    .add-new-btn {
                        &.mat-mdc-button {
                            padding: 0;
                            height: auto;
                            min-width: auto;
                            font: {
                                size: var(--fontSize) !important;
                                weight: normal;
                            };
                            &:not(:disabled) {
                                color: var(--daxaColor);
                            }
                        }
                    }
                }
                .mat-mdc-card-subtitle {
                    display: flex;
                    align-items: center;

                    .mat-mdc-button {
                        &.card-header-menu-btn {
                            height: auto;
                            min-width: auto;
                            padding: 0 18px 0 0;
                            color: var(--blackColor);
                            font-size: 15px !important;

                            &::after {
                                top: 50%;
                                right: -4px;
                                content: "\ea4e";
                                margin-top: -.5px;
                                position: absolute;
                                color: var(--daxaColor);
                                transform: translateY(-50%);
                                font-family: remixicon !important;
                            }
                        }
                        &.card-header-link-btn {
                            padding: 0;
                            height: auto;
                            min-width: auto;
                            color: var(--blackColor);
                            font-size: 15px !important;
                        }
                    }
                    .search-box {
                        width: 360px;

                        i {
                            transform: translateY(-50%);
                            color: var(--blackColor);
                            position: absolute;
                            font-size: 18px;
                            left: 13px;
                            top: 50%;
                        }
                        .input-search {
                            height: 40px;
                            font-size: 14px;
                            border-radius: 3px;
                            background: #f4f6fc;
                            color: var(--blackColor);
                            padding: {
                                left: 40px;
                                right: 15px;
                            };
                            &::placeholder {
                                font-weight: 300;
                                color: var(--bodyColor);
                                transition: var(--transition);
                            }
                            &:focus {
                                &::placeholder {
                                    color: transparent;
                                }
                            }
                        }
                    }
                    .daxa-badge {
                        background: rgba(46, 212, 126, 0.10);
                        border: 1px solid var(--successColor);
                        padding: 1px 5px 1px 22.84px;
                        color: var(--successColor);
                        border-radius: 2px;
                        font-size: 14px;

                        i {
                            top: 50%;
                            left: 5px;
                            font-size: 14px;
                            position: absolute;
                            transform: translateY(-50%);
                        }
                        &.down {
                            color: var(--dangerColor);
                            border-color: var(--dangerColor);
                            background: rgba(231, 76, 60, 0.10);
                        }
                    }
                    .buttons-list {
                        .mat-mdc-button {
                            height: auto;
                            min-width: auto;
                            position: relative;
                            border-radius: 2px;
                            display: inline-block;
                            color: var(--blackColor);
                            font-size: 14px !important;
                            padding: 5px 12px 5px 32px;
                            border: 1px solid #e0e0e0;

                            .mdc-button__label {
                                position: unset;

                                i {
                                    top: 50%;
                                    left: 10px;
                                    font-size: 16px;
                                    position: absolute;
                                    color: var(--daxaColor);
                                    transform: translateY(-50%);
                                }
                            }
                            &.active {
                                color: var(--whiteColor);
                                border-color: var(--daxaColor);
                                background-color: var(--daxaColor);

                                .mdc-button__label {
                                    i {
                                        color: var(--whiteColor) !important;
                                    }
                                }
                            }
                            &:not(:first-child) {
                                margin-left: 8px;
                            }
                        }
                    }
                    .info-buttons-list {
                        .mat-mdc-button {
                            padding: 0;
                            height: auto;
                            min-width: auto;
                            margin-left: 18px;
                            display: inline-block;
                            color: var(--bodyColor);
                            transition: var(--transition);

                            i {
                                font-size: 18px;
                            }
                            &:hover {
                                color: var(--daxaColor);
                            }
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                    .add-new-btn {
                        &.mat-mdc-button {
                            padding: 0;
                            height: auto;
                            min-width: auto;
                            font: {
                                size: var(--fontSize) !important;
                                weight: normal;
                            };
                            &:not(:disabled) {
                                color: var(--daxaColor);
                            }
                        }
                    }
                }
            }
        }
        .mat-mdc-card-content {
            padding: 0;

            .apexcharts-text {
                tspan {
                    font-family: var(--fontFamily);
                }
            }
            .apexcharts-legend-text, .apexcharts-title-text {
                font-family: var(--fontFamily) !important;
            }
            .apexcharts-legend-marker {
                margin-right: 7px;
                border-radius: 0 !important;
            }
            .apexcharts-xaxistooltip, .apexcharts-yaxistooltip {
                border: 0;
                color: var(--blackColor);
                background-color: var(--whiteColor);
                box-shadow: 0px 4px 34px rgba(101, 96, 240, 0.1);

                .apexcharts-xaxistooltip-text {
                    font: {
                        weight: 500;
                        size: 14px !important;
                        family: var(--fontFamily) !important;
                    };
                }
                &::after, &::before {
                    display: none;
                }
            }
            .apexcharts-tooltip {
                box-shadow: 0px 0px 24px 0px rgba(71, 85, 105, 0.10);
                background-color: var(--whiteColor);
                border: 0;

                * {
                    color: var(--bodyColor);
                    font: {
                        size: 14px !important;
                        family: var(--fontFamily) !important;
                    };
                }
                .apexcharts-tooltip-title {
                    border-bottom: 0;
                    margin-bottom: 0;
                    color: var(--blackColor);
                    padding: 9px 35px 9px 12px;
                    background-color: #e7effd;
                    font-size: var(--fontSize) !important;
                }
                .apexcharts-tooltip-series-group {
                    padding: 9px 35px 9px 12px;
                    border-bottom: 1px dashed #e0e0e0;
                    background-color: var(--whiteColor) !important;

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .apexcharts-tooltip-y-group {
                padding: 0;
            }
            .apexcharts-tooltip-text-goals-value, .apexcharts-tooltip-text-y-value, .apexcharts-tooltip-text-z-value {
                color: var(--blackColor);
                font-weight: 500;
                margin-left: 0;
            }
            .apexcharts-tooltip-marker {
                border-radius: 0;
                margin-right: 6px;
            }
            .apexcharts-tooltip-rangebar {
                padding: 0;

                .series-name {
                    margin-bottom: 0;
                    padding: 9px 35px 9px 12px;
                    background-color: #e7effd;
                    color: var(--blackColor) !important;
                    font: {
                        weight: 500;
                        size: var(--fontSize) !important;
                    };
                }
                .category {
                    font-weight: 500;
                    color: var(--blackColor);
                }
                div {
                    &:nth-child(2) {
                        padding: 9px 35px 9px 12px;
                    }
                }
            }
            .apexcharts-datalabel, .apexcharts-datalabel-label, .apexcharts-datalabel-value, .apexcharts-datalabels, .apexcharts-pie-label, .apexcharts-yaxis-title-text, .apexcharts-subtitle-text, .apexcharts-text {
                font-family: var(--fontFamily) !important;
            }
            .NgxEditor__Wrapper {
                border-radius: 5px;
                border-color: #dae0ec;

                .NgxEditor__Content {
                    padding: 16px;

                    p {
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .NgxEditor__Placeholder {
                        &::before {
                            color: var(--bodyColor);
                        }
                    }
                }
                .NgxEditor {
                    border-radius: 5px;
                    min-height: 180px;
                }
                .NgxEditor__MenuItem {
                    .NgxEditor__MenuItem--IconContainer {
                        cursor: pointer;

                        svg {
                            fill: var(--blackColor);
                        }
                    }
                    &.NgxEditor__MenuItem--Active, .NgxEditor__MenuItem--Active {
                        background-color: var(--daxaColor);
                        color: var(--whiteColor);

                        svg {
                            fill: var(--whiteColor);
                        }
                    }
                }
                .NgxEditor__Dropdown {
                    cursor: pointer;

                    .NgxEditor__Dropdown--Selected, .NgxEditor__Dropdown--Open {
                        color: var(--whiteColor);
                        background-color: var(--daxaColor);
                    }
                }
                .NgxEditor__MenuItem--Button {
                    border: 0;
                    cursor: pointer;
                    border-radius: 3px;
                    color: var(--whiteColor);
                    font-size: 14px !important;
                    background-color: var(--dangerColor);
                }
                .NgxEditor__MenuBar {
                    padding: 0;
                    border-color: #dae0ec;
                    border-radius: 5px 5px 0 0;
                }
                .NgxEditor__Seperator {
                    border-color: #dae0ec;
                }
                .NgxEditor__Popup {
                    .NgxEditor__Popup--Form {
                        button {
                            border: 0;
                            cursor: pointer;
                            border-radius: 3px;
                            color: var(--whiteColor);
                            background-color: var(--daxaColor);
                        }
                    }
                    .NgxEditor__Popup--FormGroup {
                        input[type=text], input[type=url], input[type=href] {
                            border: 1px solid #dae0ec;
                            outline: 0 !important;
                            font-size: 14px;
                        }
                        label {
                            font-size: 14px;
                            cursor: pointer;

                            input {
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                }
            }
            .table-responsive {
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;

                .mdc-data-table__table {
                    overflow: hidden;
                }
                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }
                &::-webkit-scrollbar-track {
                    background-color: var(--whiteColor);
                }
                &::-webkit-scrollbar-thumb {
                    background: #ebebeb;
                }
            }
            .mat-mdc-table {
                background-color: transparent;
                box-shadow: unset;
                width: 100%;

                .mat-mdc-header-row {
                    height: auto;
                    line-height: normal;
                    color: var(--blackColor);
                    font: {
                        family: var(--fontFamily);
                        size: var(--fontSize);
                        weight: 500;
                    };
                }
                .mat-mdc-header-cell {
                    background-color: #e7effd;
                    color: var(--blackColor);
                    letter-spacing: normal;
                    line-height: initial;
                    white-space: nowrap;
                    text-align: left;
                    overflow: unset;
                    padding: 20px;
                    border: none;
                }
                .mdc-data-table__content, .mat-mdc-row {
                    line-height: initial;
                    font: {
                        family: var(--fontFamily);
                        size: var(--fontSize);
                        weight: normal;
                    };
                }
                .mat-mdc-row {
                    height: auto;
                    color: var(--blackColor);
                }
                .mat-mdc-cell {
                    background-color: var(--whiteColor);
                    border-bottom: 1px dashed #e0e0e0;
                    color: var(--bodyColor);
                    letter-spacing: normal;
                    line-height: initial;
                    white-space: nowrap;
                    text-align: left;
                    overflow: unset;
                    padding: 15px 20px;
                }
                .mat-mdc-footer-row {
                    color: var(--blackColor);
                    letter-spacing: normal;
                    line-height: initial;
                    height: auto;
                    font: {
                        family: var(--fontFamily);
                        size: var(--fontSize);
                        weight: normal;
                    };
                    .mdc-data-table__cell {
                        border-bottom: 1px dashed #e0e0e0;
                        letter-spacing: normal;
                        line-height: initial;
                        white-space: nowrap;
                        text-align: left;
                        overflow: unset;
                        padding: 20px;
                    }
                }
                .mat-mdc-no-data-row {
                    td {
                        padding: 20px;
                        border-bottom: 1px dashed #e0e0e0;
                    }
                }
                .mat-mdc-checkbox {
                    top: -1.6px;

                    .mdc-checkbox {
                        padding: 0;
                        margin: 0;

                        .mdc-checkbox__native-control {
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                        }
                        .mdc-checkbox__background {
                            top: 0;
                            left: 0;
                        }
                        .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
                            border-radius: 2px;
                        }
                        .mat-mdc-checkbox-touch-target {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .action-info {
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    .mat-mdc-button {
                        padding: 0;
                        width: auto;
                        height: auto;
                        line-height: 1;
                        min-width: auto;
                        border-radius: 0;

                        .mdc-button__label {
                            color: var(--daxaColor);
                            transition: var(--transition);

                            i {
                                font-size: 16px;
                            }
                        }
                        &:not(:first-child) {
                            margin-left: 12px;

                            .mdc-button__label {
                                color: var(--bodyColor);
                            }
                        }
                    }
                }
                .daxa-badge {
                    font-size: 15px;
                    border-radius: 3px;
                    padding: 3px 10.24px;

                    &.shipped {
                        color: var(--daxaColor);
                        background: rgba(15, 121, 243, 0.10);
                    }
                    &.pending {
                        color: var(--warningColor);
                        background: rgba(241, 179, 28, 0.10);
                    }
                    &.confirmed {
                        color: var(--successColor);
                        background: rgba(46, 212, 126, 0.10);
                    }
                    &.rejected {
                        color: var(--dangerColor);
                        background: rgba(231, 76, 60, 0.10);
                    }
                    &.new {
                        color: var(--successColor);
                        background: rgba(46, 212, 126, 0.10);
                    }
                    &.in-progress {
                        color: var(--warningColor);
                        background: rgba(241, 179, 28, 0.10);
                    }
                    &.won {
                        color: var(--daxaColor);
                        background: rgba(15, 121, 243, 0.10);
                    }
                    &.lost {
                        color: var(--dangerColor);
                        background: rgba(231, 76, 60, 0.10);
                    }
                    &.active {
                        color: var(--successColor);
                        background: rgba(46, 212, 126, 0.10);
                    }
                    &.deactive {
                        color: var(--dangerColor);
                        background: rgba(231, 76, 60, 0.10);
                    }
                    &.successful {
                        color: var(--daxaColor);
                        background: rgba(15, 121, 243, 0.10);
                    }
                    &.upcoming {
                        color: var(--daxaColor);
                        background: rgba(15, 121, 243, 0.10);
                    }
                    &.past {
                        color: var(--dangerColor);
                        background: rgba(231, 76, 60, 0.10);
                    }
                    &.read {
                        color: var(--successColor);
                        background: rgba(46, 212, 126, 0.10);
                    }
                    &.unread {
                        color: var(--dangerColor);
                        background: rgba(231, 76, 60, 0.10);
                    }
                }
                &.expandable-rows {
                    .mat-mdc-cell {
                        border: {
                            bottom-width: 0;
                            top: 1px dashed #e0e0e0;
                        };
                    }
                    .example-detail-row {
                        .mat-mdc-cell {
                            white-space: unset;
                            padding: 0;
                            border: 0;
                        }
                    }
                    .example-element-detail {
                        align-items: center;
                        overflow: hidden;
                        display: flex;
                        padding: {
                            left: 20px;
                            right: 20px;
                        };
                    }
                    .example-element-diagram {
                        border: 2px solid var(--blackColor);
                        font-weight: lighter;
                        min-width: 80px;
                        height: 104px;
                        padding: 5px;
                        margin: {
                            bottom: 15px;
                        };
                    }
                    .example-element-symbol {
                        line-height: normal;
                        font: {
                            weight: bold;
                            size: 40px;
                        };
                    }
                    .example-element-description {
                        padding: 0 15px 15px;
                    }
                    tr {
                        cursor: pointer;

                        &.example-element-row {
                            &.example-expanded-row {
                                td {
                                    background: transparent;
                                }
                            }
                            &:not(.example-expanded-row) {
                                &:hover {
                                    td {
                                        background: whitesmoke;
                                    }
                                }
                            }
                        }
                        &:first-child {
                            .mat-mdc-cell {
                                border-top-width: 0;
                            }
                        }
                        &:nth-last-child(2) {
                            .mat-mdc-cell {
                                border: {
                                    bottom-width: 1px;
                                };
                            }
                        }
                    }
                }
            }
            .file-uploader {
                outline: 0;
                min-height: 180px;
                border-radius: 5px;
                border: 1px solid #dae0ec;
                background-color: transparent;

                .upload-input {
                    color: var(--bodyColor);
                    transition: var(--transition);

                    .upload-text {
                        b {
                            font-weight: 500;
                            color: var(--blackColor);
                        }
                    }
                    &:hover {
                        color: var(--daxaColor);
                    }
                }
                .file-info {
                    margin-bottom: 3px;
                    color: var(--blackColor);
                }
                .remove-btn {
                    color: var(--bodyColor);

                    svg {
                        top: 1px;
                        position: relative;
                    }
                }
            }
            .daxa-up-down-badge {
                background: rgba(46, 212, 126, 0.10);
                border: 1px solid var(--successColor);
                padding: 1px 5px 1px 22.84px;
                color: var(--successColor);
                display: inline-block;
                position: relative;
                border-radius: 2px;
                font-size: 14px;

                i {
                    top: 50%;
                    left: 5px;
                    font-size: 14px;
                    position: absolute;
                    transform: translateY(-50%);
                }
                &.down {
                    color: var(--dangerColor);
                    border-color: var(--dangerColor);
                    background: rgba(231, 76, 60, 0.10);
                }
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
.mat-mdc-menu-panel {
    &.card-header-menu {
        .mat-mdc-menu-content {
            .mat-mdc-menu-item {
                padding: {
                    top: 8px;
                    bottom: 8px;
                };
            }
        }
    }
    &.action-menu {
        .mat-mdc-menu-content {
            .mat-mdc-menu-item {
                padding: 8px 20px 8px 42px;
                font: {
                    weight: normal;
                    size: 15px !important;
                };
                i {
                    top: 50%;
                    left: 20px;
                    margin-top: -1.5px;
                    position: absolute;
                    color: var(--daxaColor);
                    transform: translateY(-50%);
                }
            }
        }
    }
}

// Mat Select
.mat-mdc-select-panel {
    padding: 10px 0 !important;
    border-radius: 10px !important;
    box-shadow: 0px 4px 34px rgba(101, 96, 240, 0.1) !important;

    .mat-mdc-option {
        &.mdc-list-item {
            padding: 0;
            min-height: auto;
            padding: 12px 20px;
            color: var(--blackColor);
            transition: var(--transition);
            font: {
                weight: normal;
                size: 15px !important;
            };
            &:hover {
                color: var(--daxaColor);
            }
        }
        .mdc-list-item__primary-text {
            line-height: 1;
        }
        .mat-pseudo-checkbox-full {
            margin-right: 13px;
        }
    }
}

// Breadcrumb
.breadcrumb-card {
    .breadcrumb {
        .breadcrumb-item {
            font-size: 14.5px;
            display: inline-block;
            color: var(--bodyColor);
            margin: {
                left: 12px;
                right: 12px;
            };
            a {
                color: var(--bodyColor);
                padding-left: 20px;

                i {
                    left: 0;
                    top: 50%;
                    font-size: 15px;
                    margin-top: -1px;
                    position: absolute;
                    color: var(--daxaColor);
                    transform: translateY(-50%);
                }
                &:hover {
                    color: var(--daxaColor);
                }
            }
            &:before {
                top: 50%;
                width: 5px;
                height: 5px;
                right: -14px;
                content: "";
                margin-top: 1px;
                position: absolute;
                border-radius: 50%;
                background: #a0a0a0;
                transform: translateY(-50%);
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
                color: var(--blackColor);

                &::before {
                    display: none;
                }
            }
        }
    }
}
.d-breadcrumb {
    --breadcrumb-padding-x: 0;
    --breadcrumb-padding-y: 0;
    --breadcrumb-margin-bottom: 1rem;
    --breadcrumb-bg: ;
    --breadcrumb-border-radius: ;
    --breadcrumb-divider-color: var(--secondary-color);
    --breadcrumb-item-padding-x: 0.5rem;
    --breadcrumb-item-active-color: var(--secondary-color);
    display: flex;
    flex-wrap: wrap;
    padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
    margin-bottom: var(--breadcrumb-margin-bottom);
    font-size: var(--breadcrumb-font-size);
    list-style: none;
    background-color: var(--breadcrumb-bg);
    border-radius: var(--breadcrumb-border-radius);
    --secondary-color: rgba(33, 37, 41, 0.75);
    --link-color: var(--daxaColor);
}
.d-breadcrumb-item {
    &.active {
        color: var(--breadcrumb-item-active-color);
    }
    &+.d-breadcrumb-item {
        padding-left: var(--breadcrumb-item-padding-x);

        &::before {
            top: 1px;
            float: left;
            position: relative;
            padding-right: var(--breadcrumb-item-padding-x);
            color: var(--breadcrumb-divider-color);
            content: var(--breadcrumb-divider, "/");
        }
    }
    a {
        color: var(--link-color);
    }
}

// Pagination
.pagination-card {
    p {
        font-size: 15px;
    }
    .pagination {
        .pagination-item {
            margin: {
                left: 3px;
                right: 3px;
            };
            .mat-mdc-button {
                padding: 0;
                width: 35px;
                height: 35px;
                min-width: auto;
                font-size: 18px;
                border-radius: 3px;
                color: var(--bodyColor);
                border: 1px solid #e0e0e0;
                background-color: var(--whiteColor);

                i {
                    top: 2px;
                    font-size: 20px;
                    position: relative;
                }
                &.active {
                    color: var(--whiteColor);
                    border-color: var(--daxaColor);
                    background-color: var(--daxaColor);
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.mat-mdc-paginator {
    background-color: transparent !important;

    .mat-mdc-paginator-outer-container {
        display: block;

        .mat-mdc-paginator-container {
            padding: 0;
            min-height: auto;
            justify-content: space-between;

            .mat-mdc-paginator-navigation-last, .mat-mdc-paginator-navigation-first {
                display: none;
            }
            .mat-mdc-paginator-page-size-label {
                margin: 0;
                font-size: 15px;
                color: var(--bodyColor);
            }
            .mat-mdc-paginator-page-size {
                margin-right: 0;
            }
            .mat-mdc-select-trigger {
                width: auto;
            }
            .mat-mdc-text-field-wrapper {
                border: 0;
            }
            .mdc-text-field {
                border-radius: 0;
                padding: {
                    left: 0;
                    right: 0;
                };
            }
            .mat-mdc-form-field {
                width: auto;
                margin: 0 0 0 10px;
            }
            .mat-mdc-select-value-text {
                color: var(--blackColor);
                font: {
                    size: 15px;
                    weight: 500;
                };
            }
            .mat-mdc-form-field-infix {
                min-height: auto;
                width: 100px;
                padding: {
                    top: 0;
                    bottom: 0;
                };
            }
            .mat-mdc-select-arrow {
                color: var(--bodyColor);
            }
            .mat-mdc-select-arrow-wrapper {
                margin-left: 5px;
            }
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
                border: none !important;
            }
        }
    }
    .mat-mdc-paginator-range-actions {
        .mat-mdc-paginator-range-label {
            font-size: 15px;
            margin: 0 15px 0 0;
            color: var(--bodyColor);
        }
        .mat-mdc-icon-button {
            border: 1px solid #e0e0e0;
            color: var(--bodyColor);
            padding: 4.5px 0 0 0;
            border-radius: 3px;
            margin-left: 5px;
            height: 35px;
            width: 35px;

            .mat-mdc-button-persistent-ripple {
                border-radius: 3px;
            }
            .mat-mdc-paginator-icon {
                fill: var(--bodyColor);
            }
            &[disabled] {
                .mat-mdc-paginator-icon {
                    fill: var(--mat-paginator-disabled-icon-color);
                }
            }
        }
    }
}
.pagination-list {
    --pagination-padding-x: 0.75rem;
    --pagination-padding-y: 0.375rem;
    --pagination-font-size: 1rem;
    --pagination-color: var(--link-color);
    --pagination-bg: var(--body-bg);
    --pagination-border-width: var(--border-width);
    --pagination-border-color: var(--border-color);
    --pagination-border-radius: var(--border-radius);
    --pagination-hover-color: var(--link-hover-color);
    --pagination-hover-bg: var(--tertiary-bg);
    --pagination-hover-border-color: var(--border-color);
    --pagination-focus-color: var(--link-hover-color);
    --pagination-focus-bg: var(--secondary-bg);
    --pagination-active-color: var(--whietColor);
    --pagination-active-bg: var(--daxaColor);
    --pagination-active-border-color: var(--daxaColor);
    --pagination-disabled-color: var(--secondary-color);
    --pagination-disabled-bg: var(--secondary-bg);
    --pagination-disabled-border-color: var(--border-color);
    display: flex;
    padding-left: 0;
    list-style: none;
    --link-color: var(--daxaColor);
    --body-bg: var(--whietColor);
    --border-width: 1px;
    --border-color: #dee2e6;
    --border-radius: 0.375rem;
    --link-hover-color: var(--daxaColor);
    --tertiary-bg: #f8f9fa;

    .page-link {
        position: relative;
        display: block;
        padding: var(--pagination-padding-y) var(--pagination-padding-x);
        font-size: var(--pagination-font-size);
        color: var(--pagination-color);
        text-decoration: none;
        background-color: var(--pagination-bg);
        border: var(--pagination-border-width) solid var(--pagination-border-color);
        transition: var(--transition);

        &:hover, &.active {
            z-index: 2;
            color: var(--pagination-hover-color);
            background-color: var(--pagination-hover-bg);
            border-color: var(--pagination-hover-border-color);
        }
        &:focus {
            z-index: 3;
            color: var(--pagination-focus-color);
            background-color: var(--pagination-focus-bg);
            outline: 0;
        }
    }
    .page-item {
        &:first-child {
            .page-link {
                border-top-left-radius: var(--pagination-border-radius);
                border-bottom-left-radius: var(--pagination-border-radius);
            }
        }
        &:not(:first-child) {
            .page-link {
                margin-left: calc(var(--border-width) * -1);
            }
        }
        &:last-child {
            .page-link {
                border-top-right-radius: var(--pagination-border-radius);
                border-bottom-right-radius: var(--pagination-border-radius);
            }
        }
    }
}

// Mat Tabs
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: var(--daxaColor);
    --mat-tab-header-active-label-text-color: var(--daxaColor);
    --mat-tab-header-active-ripple-color: var(--daxaColor);
    --mat-tab-header-inactive-ripple-color: var(--daxaColor);
    --mat-tab-header-active-focus-label-text-color: var(--daxaColor);
    --mat-tab-header-active-hover-label-text-color: var(--daxaColor);
    --mat-tab-header-active-focus-indicator-color: var(--daxaColor);
    --mat-tab-header-active-hover-indicator-color: var(--daxaColor);
}

// Card Borderd Theme
.card-borderd-theme {
    .mat-mdc-card {
        box-shadow: var(--borderBoxShadow) !important;
    }
}

// Card Border Radius
.card-border-radius {
    .border-radius, .border-top-radius, .border-bottom-radius {
        border-radius: 0 !important;
    }
    .header-area {
        .header-left-side {
            .search-box {
                .input-search {
                    border-radius: 0 !important;
                }
            }
        }
    }
    .mat-mdc-card {
        border-radius: 0 !important;

        &.daxa-card {
            .mat-mdc-card-header {
                .mat-mdc-card-header-text {
                    .mat-mdc-card-subtitle, .mat-mdc-card-title {
                        .search-box {
                            .input-search {
                                border-radius: 0 !important;
                            }
                        }
                    }
                }
            }
            .mat-mdc-card-content {
                .apexcharts-tooltip {
                    border-radius: 0 !important;

                    * {
                        border-radius: 0 !important;
                    }
                }
                .NgxEditor__Wrapper {
                    border-radius: 0 !important;

                    * {
                        border-radius: 0 !important;
                    }
                }
            }
            &.chat-projects-user-card {
                .mat-mdc-card-content {
                    .chat-body {
                        ul {
                            li {
                                .message {
                                    p {
                                        border-radius: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.active-project-card {
                .mat-mdc-card-content {
                    .project-status {
                        border-radius: 0 !important;
                    }
                }
            }
            .daxa-badge {
                border-radius: 0 !important;
            }
            &.todays-course-card {
                .mat-mdc-card-content {
                    .course-item {
                        .image {
                            img {
                                border-radius: 0 !important;
                            }
                        }
                    }
                }
            }
            &.chat-sidebar-card, &.sidebar-card, &.filter-sidebar-card {
                .mat-mdc-card-content {
                    .search-box {
                        .input-search {
                            border-radius: 0 !important;
                        }
                    }
                }
            }
            &.chat-card {
                .mat-mdc-card-content {
                    .chat-body {
                        ul {
                            li {
                                .message {
                                    p {
                                        border-radius: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.product-details-card {
                .mat-mdc-card-content {
                    .product-details-image {
                        border-radius: 0 !important;

                        * {
                            border-radius: 0 !important;
                        }
                    }
                    .add-to-cart {
                        .quantity-counter {
                            input {
                                border-radius: 0 !important;
                            }
                        }
                    }
                }
            }
            .quantity-counter {
                input {
                    border-radius: 0 !important;
                }
            }
            &.project-discussion-card, &.ticket-details-card {
                .mat-mdc-card-content {
                    .chat-body {
                        ul {
                            li {
                                &.right {
                                    .message {
                                        p {
                                            border-radius: 0 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            input {
                border-radius: 0 !important;
            }
            &.search-card {
                .mat-mdc-card-content {
                    .search-item {
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }
    .mat-mdc-paginator {
        .mat-mdc-paginator-range-actions {
            .mat-mdc-icon-button {
                border-radius: 0 !important;
            }
        }
    }
    .mat-mdc-checkbox, .mat-mdc-button, .fc, .mat-calendar, .file-uploader, .mat-mdc-tab-labels, .alert, .badge, .mat-button-toggle-group, .mat-mdc-outlined-button, .mat-mdc-unelevated-button, .mdc-evolution-chip-set, .example-boundary, .example-container, .example-list, .mat-expansion-panel, .pagination-list, .pagination, .mat-mdc-progress-bar {
        border-radius: 0 !important;

        * {
            border-radius: 0 !important;
        }
    }
    .mat-mdc-raised-button {
        border-radius: 0 !important;
    }
    .mat-mdc-form-field {
        border-radius: 0 !important;

        * {
            border-radius: 0 !important;
        }
    }
}

/* Max width 767px */
@media only screen and (max-width : 767px) {

    // Mat Body
    .mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
        font-size: 14px;
    }

    // Mat Paragraph
    .mat-body p, .mat-body-2 p, .mat-typography .mat-body p, .mat-typography .mat-body-2 p, .mat-typography p {
        font-size: 14px;
    }

    // Mat Heading
    .mat-h1, .mat-headline-5, .mat-typography .mat-h1, .mat-typography .mat-headline-5, .mat-typography h1 {
        font-size: 36px;
    }
    .mat-h2, .mat-headline-6, .mat-typography .mat-h2, .mat-typography .mat-headline-6, .mat-typography h2 {
        font-size: 28px;
    }
    .mat-h3, .mat-subtitle-1, .mat-typography .mat-h3, .mat-typography .mat-subtitle-1, .mat-typography h3 {
        font-size: 24px;
    }
    .mat-h4, .mat-body-1, .mat-typography .mat-h4, .mat-typography .mat-body-1, .mat-typography h4 {
        font-size: 20px;
    }
    .mat-h5, .mat-typography .mat-h5, .mat-typography h5 {
        font-size: 16px;
    }

    // Mat Card
    .mat-mdc-card {
        &.daxa-card {
            padding: 15px;

            .mat-mdc-card-header {
                margin-bottom: 15px;

                .mat-mdc-card-header-text {
                    .mat-mdc-card-title {
                        .search-box {
                            width: 100%;
                        }
                        .mat-mdc-button {
                            &.add-new-btn {
                                font-size: 14px !important;
                            }
                        }
                    }
                    .mat-mdc-card-subtitle {
                        .mat-mdc-button {
                            &.card-header-menu-btn {
                                padding: 0 15px 0 0;
                                font-size: 14px !important;
                            }
                            &.card-header-link-btn {
                                font-size: 14px !important;
                            }
                        }
                        .search-box {
                            width: 100%;
                        }
                        .buttons-list {
                            margin-bottom: -8px;

                            .mat-mdc-button {
                                margin: {
                                    right: 8px;
                                    bottom: 8px;
                                };
                                &:not(:first-child) {
                                    margin-left: 0;
                                }
                            }
                        }
                        .mat-mdc-button {
                            &.add-new-btn {
                                font-size: 14px !important;
                            }
                        }
                        .info-buttons-list {
                            .mat-mdc-button {
                                margin-left: 15px;

                                i {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .mat-mdc-card-content {
                .apexcharts-tooltip {
                    .apexcharts-tooltip-title {
                        padding: 8px 25px 8px 10px;
                    }
                    .apexcharts-tooltip-series-group {
                        padding: 8px 25px 8px 10px;
                    }
                }
                .apexcharts-tooltip-rangebar {
                    .series-name {
                        padding: 8px 25px 8px 10px;
                        font-size: 14px !important;
                    }
                    div {
                        &:nth-child(2) {
                            padding: 8px 25px 8px 10px;
                        }
                    }
                }
                .NgxEditor__Wrapper {
                    .NgxEditor {
                        min-height: 150px;
                    }
                }
                .mat-mdc-table {
                    .mat-mdc-header-row {
                        font-size: 14px;
                    }
                    .mat-mdc-header-cell {
                        padding: 15px;
                    }
                    .mdc-data-table__content, .mat-mdc-row {
                        font-size: 14px;
                    }
                    .mat-mdc-cell {
                        padding: 15px;
                    }
                    .mat-mdc-footer-row {
                        font-size: 14px;

                        .mdc-data-table__cell {
                            padding: 15px;
                        }
                    }
                    .mat-mdc-no-data-row {
                        td {
                            padding: 15px;
                        }
                    }
                    &.expandable-rows {
                        .example-element-detail {
                            display: block;
                            padding: {
                                left: 15px;
                                right: 15px;
                            };
                        }
                        .example-element-diagram {
                            max-width: 80px;
                            margin: {
                                top: 0;
                                bottom: 15px;
                            };
                        }
                        .example-element-description {
                            padding: 0 0 15px;
                        }
                    }
                    .daxa-badge {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .mat-mdc-menu-panel {
        &.card-header-menu {
            .mat-mdc-menu-content {
                .mat-mdc-menu-item {
                    font-size: 14px;
                }
            }
        }
        &.action-menu {
            .mat-mdc-menu-content {
                .mat-mdc-menu-item {
                    font-size: 14px;
                }
            }
        }
    }

    // Mat Select
    .mat-mdc-select-panel {
        .mat-mdc-option {
            &.mdc-list-item {
                padding: 7px 20px;
                font-size: 14px;
            }
        }
    }

    // Breadcrumb
    .breadcrumb-card {
        .breadcrumb {
            .breadcrumb-item {
                margin-top: 9px;
            }
        }
    }

    // Pagination
    .pagination-card {
        p {
            font-size: 14px;
        }
        .pagination {
            margin-top: 12px !important;

            .pagination-item {
                .mat-mdc-button {
                    width: 32px;
                    height: 32px;
                    font-size: 15px;

                    i {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .mat-mdc-paginator {
        .mat-mdc-paginator-outer-container {
            .mat-mdc-paginator-container {
                display: block;

                .mat-mdc-paginator-page-size-label {
                    font-size: 14px;
                }
                .mat-mdc-form-field {
                    margin: 0 0 0 5px;
                }
                .mat-mdc-select-value-text {
                    font-size: 14px;
                }
            }
        }
        .mat-mdc-paginator-range-actions {
            margin-top: 10px;

            .mat-mdc-paginator-range-label {
                margin: 0 10px 0 0;
                font-size: 14px;
            }
            .mat-mdc-icon-button {
                padding: 2.5px 0 0 0;
                height: 32px;
                width: 32px;
            }
        }
    }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width : 576px) and (max-width : 767px) {

    // Mat Card
    .mat-mdc-card {
        &.daxa-card {
            padding: 20px;

            .mat-mdc-card-content {
                .mat-mdc-table {
                    .mat-mdc-table {
                        .mat-mdc-header-cell {
                            padding: {
                                left: 20px;
                                right: 20px;
                            };
                        }
                        .mat-mdc-cell {
                            padding: {
                                left: 20px;
                                right: 20px;
                            };
                        }
                        .mat-mdc-footer-row {
                            .mdc-data-table__cell {
                                padding: {
                                    left: 20px;
                                    right: 20px;
                                };
                            }
                        }
                        .mat-mdc-no-data-row {
                            td {
                                padding: {
                                    left: 20px;
                                    right: 20px;
                                };
                            }
                        }
                        &.expandable-rows {
                            .example-element-detail {
                                padding: {
                                    left: 20px;
                                    right: 20px;
                                };
                            }
                        }
                    }
                }
            }
        }
    }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width : 768px) and (max-width : 991px) {

    // Mat Body
    .mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
        font-size: 15px;
    }

    // Mat Paragraph
    .mat-body p, .mat-body-2 p, .mat-typography .mat-body p, .mat-typography .mat-body-2 p, .mat-typography p {
        font-size: 15px;
    }

    // Mat Card
    .mat-mdc-card {
        &.daxa-card {
            .mat-mdc-card-header {
                .mat-mdc-card-header-text {
                    .mat-mdc-card-title {
                        .mat-mdc-button {
                            &.add-new-btn {
                                font-size: 15px !important;
                            }
                        }
                    }
                    .mat-mdc-card-subtitle {
                        .mat-mdc-button {
                            &.add-new-btn {
                                font-size: 15px !important;
                            }
                        }
                    }
                }
            }
            .mat-mdc-card-content {
                .mat-mdc-table {
                    .mat-mdc-header-row {
                        font-size: 15px;
                    }
                    .mdc-data-table__content, .mat-mdc-row {
                        font-size: 15px;
                    }
                    .mat-mdc-footer-row {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    // Pagination
    .pagination-card {
        .pagination {
            margin-top: 12px !important;
        }
    }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width : 992px) and (max-width : 1199px) {}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {}

.mat-typography h2 {
    margin: 0px !important;
}
.mat-mdc-card-header-text mat-card-title.mat-mdc-card-title.w-100.p30 {
    padding: 0px !important;
}
.mat-mdc-card.daxa-card .mat-mdc-card-header .mat-icon:hover {
    color: red;
}
